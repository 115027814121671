/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


.downloadWrapper{
  display: flex;
  min-height: 110vh;
  background-image: radial-gradient( circle 763px at 18.3% 24.1%,  rgba(255,249,137,1) 7.4%, rgba(226,183,40,1) 58.3% );
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loaderWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.loaderMain {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.certificate-wrapper{
  width:794px; 
  height:1123px;
  display: block;
  margin: 0px auto;
  
}

.certificate-data{
  display: flex;
  position: absolute;
  flex-direction: column;
  width:794px; 
  z-index: 9999;
}
.studRegNo{
  font-size: 18px;
  color: #1b1717;
  position: absolute;
  margin-top: 76px;
  margin-left: 205px;
  font-weight: 600;
}

.courseName{
  font-size: 18px;
  color: #000;
  width: 498px;
  margin-top: 396px;
  margin-left: 208px;
  font-weight: 500;
  position: absolute;
}

.studName{
  font-size: 38px;
  color: #BD702D;
  width: 794px;
  position: absolute;
  margin-top: 320px;
  text-align: center;
  font-weight: 600;
}

.courseDuration{
  font-size: 18px;
  color: #000;
  width: 440px;
  margin-top: 516px;
  margin-left: 186px;
  font-weight: 500;
  position: absolute;
}

.courseGrade{
  font-size: 18px;
  color: #000;
  width: 440px;
  margin-top: 516px;
  margin-left: 566px;
  font-weight: 500;
  position: absolute;
}

.softwareCovered{
  font-size: 18px;
  color: #000;
  width: 440px;
  margin-top: 456px;
  margin-left: 260px;
  font-weight: 500;
  position: absolute;
}

.gardianName{
  font-size: 28px;
  color: #000;
  text-align: center;
  width: 794px;
  margin-top: 50px;
  font-weight: 600;
}

.awardedDate{
  font-weight: 600;
  font-size: 18px;
  color: #1a1a1a;
  display: block;
  margin-top: 976px;
  margin-left: 542px;
  position: absolute;
  font-weight: 700;
}

.qrCode{
  margin-left: 330px;
  margin-top: 760px;
  position: absolute;
}

